import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'src/components/PrivateRoute';
import {
    AccountSettings,
    AccountSubscription,
    AccountOrders,
    OrderDetails,
    AccountCommunications,
} from 'src/components/account';
import NotFoundPage from 'src/pages/404';

const Account = () => (
    <Router>
        <PrivateRoute path="/account/" component={AccountSettings} />
        <PrivateRoute
            path="/account/subscription"
            component={AccountSubscription}
        />
        <PrivateRoute path="/account/orders" component={AccountOrders} />
        <PrivateRoute path="/account/order/:orderId" component={OrderDetails} />
        <PrivateRoute
            path="/account/communications"
            component={AccountCommunications}
        />
        <NotFoundPage default />
    </Router>
);

export default Account;
