/* eslint-disable no-useless-escape */
import React from 'react';
import { format, parse } from 'date-fns';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { withFormik } from 'formik';
import { Button, Form, Divider } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';
import PhoneInputField from 'src/components/account/PhoneInputField';
import {
    getErrorMessage,
    FORM_MESSAGES,
    validateEmail,
} from 'src/utils/form-utils';

import accountStyles from 'src/styles/account.module.scss';

const DATE_FORMAT = 'yyyy-MM-dd'; // format in/out of SDK
const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy'; // format presented in UI

const genderOptions = [
    { key: '0', value: '', text: '--' },
    { key: 'female', value: 'female', text: 'Female' },
    { key: 'male', value: 'male', text: 'Male' },
    { key: 'non-binary', value: 'non-binary', text: 'Non-Binary' },
    { key: 'other', value: 'other', text: 'Other' },
];

const AccountSettingsForm = ({
    dirty,
    values,
    errors,
    status,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldValue,
    changePasswordRedirectPath,
}) => (
    <Form onSubmit={handleSubmit} className={accountStyles.accountSettings}>
        {errors.general && <h3 style={{ color: 'red' }}>{errors.general}</h3>}
        {status?.successMessage && (
            <h3 style={{ color: 'green' }}>{status.successMessage}</h3>
        )}
        <Form.Input
            label={{
                children: 'First Name',
                htmlFor: 'account-first-name',
            }}
            id="account-first-name"
            name="firstName"
            type="text"
            placeholder="First Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={errors.firstName ? getErrorMessage(errors.firstName) : false}
        />
        <Form.Input
            label={{
                children: 'Last Name',
                htmlFor: 'account-last-name',
            }}
            id="account-last-name"
            name="lastName"
            type="text"
            placeholder="Last Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={errors.lastName ? getErrorMessage(errors.lastName) : false}
        />
        <Form.Input
            label={{
                children: 'Email',
                htmlFor: 'account-email',
            }}
            id="account-email"
            name="email"
            type="email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors.email ? getErrorMessage(errors.email) : false}
        />
        <Form.Input
            label={{
                children: `Birthday (${DATE_DISPLAY_FORMAT.toUpperCase()})`,
                htmlFor: 'account-birthday',
            }}
            id="account-birthday"
            name="birthday"
            type="text"
            placeholder={`Birthday (${DATE_DISPLAY_FORMAT.toUpperCase()})`}
            onChange={(e) => {
                const formattedMonth = e.target.value.replace(
                    /^(\d\d)(\d)$/g,
                    '$1/$2'
                );
                const formattedDay = formattedMonth.replace(
                    /^(\d\d\/\d\d)(\d+)$/g,
                    '$1/$2'
                );
                const formattedYear = formattedDay.replace(/[^\d\/]/g, '');
                e.target.value = formattedYear;
                handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.birthday}
            error={errors.birthday ? getErrorMessage(errors.birthday) : false}
        />
        <Form.Field
            id="account-gender"
            name="gender"
            label="Gender"
            placeholder="Gender"
            control="select"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.gender}
            error={errors.gender ? getErrorMessage(errors.gender) : false}
        >
            {genderOptions.map((gender) => (
                <option key={gender.key} value={gender.value}>
                    {gender.text}
                </option>
            ))}
        </Form.Field>
        <PhoneInputField
            form={{
                errors,
                handleBlur,
                setFieldValue,
                touched,
            }}
            field={{
                name: 'phone',
                value: values.phone,
            }}
        />
        <Button
            className={accountStyles.accountSettingsButton}
            disabled={isSubmitting || !dirty}
            onClick={() => resetForm(values)}
            basic
        >
            Cancel
        </Button>
        <Button
            className={accountStyles.accountSettingsButton}
            type="submit"
            disabled={isSubmitting || !dirty || !isEmpty(errors)}
            primary
            basic
        >
            Save
        </Button>
        <Divider className={accountStyles.accountSettingsDivider} />
        <Button
            as={Link}
            to={`/loginHelp/resetPassword/?email=${values.email}&redirectPath=${changePasswordRedirectPath}`}
            basic
            secondary
        >
            Change Password
        </Button>
    </Form>
);

const formatBirthday = (birthday) =>
    birthday.length === 10
        ? format(parse(birthday, DATE_DISPLAY_FORMAT, new Date()), DATE_FORMAT)
        : birthday;

const handleSubmit = (
    formValues,
    { props, setSubmitting, setFieldError, resetForm, setStatus }
) => {
    const newVersion = formValues.version + 1;
    const onFailure = (message) => setFieldError('general', message);
    const onFulfill = () => setSubmitting(false);
    const onSuccess = () => {
        resetForm({ values: { ...formValues, version: newVersion } });
        setStatus({ successMessage: undefined });
    };

    props.dispatch(
        Actions.account.saveUserAttrs.trigger({
            ...formValues,
            birthday: formatBirthday(formValues.birthday),
            version: newVersion,
            onFailure,
            onFulfill,
            onSuccess,
        })
    );
};

const validate = (values) => {
    const errors = {};

    if (isEmpty(values.firstName)) {
        errors.firstName = FORM_MESSAGES.requiredField;
    }

    if (isEmpty(values.lastName)) {
        errors.lastName = FORM_MESSAGES.requiredField;
    }
    const emailError = validateEmail(values);

    if (emailError.email) {
        errors.email = emailError.email;
    }

    const formattedBirthday = formatBirthday(values.birthday);

    if (formattedBirthday === values.birthday) {
        errors.birthday = 'Invalid format';
    }

    return errors;
};

const mapPropsToValues = ({ userAttrs: { version, object: attrs } }) => ({
    version: version || '',
    firstName: attrs?.first_name || '',
    lastName: attrs?.last_name || '',
    email: attrs?.email || '',
    birthday: attrs?.birthday
        ? format(
            parse(attrs.birthday, DATE_FORMAT, new Date()),
            DATE_DISPLAY_FORMAT
        )
        : '',
    gender: attrs?.gender,
    phone: attrs?.phone,
});

const mapPropsToStatus = ({ isChangePasswordSuccess }) => ({
    successMessage: isChangePasswordSuccess
        ? 'Password Successfully Changed'
        : undefined,
});

const FormikAccountSettingsForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToStatus,
    mapPropsToValues,
    enableReinitialize: true,
})(AccountSettingsForm);

export default connect()(FormikAccountSettingsForm);
