import React from 'react';
import { Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import { navigate } from 'gatsby';

import accountStyles from 'src/styles/account.module.scss';

const BodyRow = ({ id, date, status, total }) => {
    const onOrderClick = () => {
        navigate(`/account/order/${id}`);
    };

    return (
        <Table.Row
            className={accountStyles.accountOdersBodyRow}
            key={id}
            onClick={onOrderClick}
        >
            <Table.Cell className="heavy" width={1}>
                {id.substr(0, 6)}
            </Table.Cell>
            <Table.Cell width={1}>{format(date, 'M/d/yy')}</Table.Cell>
            <Table.Cell width={1}>{status}</Table.Cell>
            <Table.Cell width={1}>${total}</Table.Cell>
        </Table.Row>
    );
};

export default BodyRow;
