import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import Selectors from 'src/state/root-selectors';

const PrivateRoute = ({
    component: Component,
    location,
    loggedIn,
    ...rest
}) => {
    // If we’re not logged in, redirect to SSO
    if (!loggedIn) {
        navigate(`/continue`);
        return null;
    }

    return <Component {...rest} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    location: PropTypes.object,
    loggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

export default connect(mapStateToProps)(PrivateRoute);
