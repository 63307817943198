import React from 'react';

import { connect } from 'react-redux';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
// import Actions from 'src/state/root-actions';

const AccountSubscription = ({ path }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    /*
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        const onSuccess = (sub) => {
            if (sub) {
                setSubscription(sub);
            }
        };

        dispatch(Actions.account.getSubscription.trigger({ onSuccess }));
    }, []);
    */

    return (
        <AccountPageWrap path={path} title="My Subscription">
            TODO
        </AccountPageWrap>
    );
};

export default connect()(AccountSubscription);
