import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Form } from 'semantic-ui-react';

import { getErrorMessage } from 'src/utils/form-utils';

import 'react-phone-number-input/style.css';

const PhoneInputField = (props) => {
    const {
        className,
        field: { name, value },
        form: { errors, handleBlur, setFieldValue, touched },
        label,
        country,
        onChange,
    } = props;

    const [isFocused, setFocused] = useState(false);
    const isError = getIn(touched, name) && getIn(errors, name);
    const errorStyle = isError ? 'error' : '';
    const filledStyle = isFocused || value ? 'filled' : '';

    const handleInputBlur = (e) => {
        setFocused(false);
        handleBlur(e);
    };

    const handleInputFocus = () => {
        setFocused(true);
    };

    const onValueChange = (phoneNumber) => {
        setFieldValue(name, phoneNumber);

        if (onChange !== null) {
            onChange(phoneNumber);
        }
    };

    return (
        <Form.Field className={`${className} ${errorStyle} ${filledStyle}`}>
            <label htmlFor={name}>{label}</label>
            <PhoneInput
                placeholder="Enter phone number"
                name={name}
                value={value}
                onChange={onValueChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultCountry={country}
                displayInitialValueAsLocalNumber
            />
            {isError && getErrorMessage(getIn(errors, name))}
        </Form.Field>
    );
};

PhoneInputField.propTypes = {
    className: PropTypes.string,
    form: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    country: PropTypes.string,
};

PhoneInputField.defaultProps = {
    className: '',
    label: 'Phone Number',
    onChange: null,
    country: 'US',
    disabled: false,
};

export default PhoneInputField;
