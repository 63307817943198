import React from 'react';

import { Grid } from 'semantic-ui-react';

import AccountMenu from 'src/components/account/AccountMenu';
import accountStyles from 'src/styles/account.module.scss';

const AccountPageWrap = ({ title, path, children }) => {
    let activeMenuItem;

    if (path.match(/account\/?$/)) {
        activeMenuItem = 'account';
    } else if (path.match(/subscription\/?$/)) {
        activeMenuItem = 'subscription';
    } else if (path.match(/orders\/?$/)) {
        activeMenuItem = 'orders';
    } else if (path.match(/communications\/?$/)) {
        activeMenuItem = 'communications';
    }

    return (
        <div className={accountStyles.accountPageWrap}>
            <Grid container>
                <Grid.Column width={4}>
                    <AccountMenu active={activeMenuItem} />
                </Grid.Column>
                {/* <Grid.Column width={1}></Grid.Column> */}
                <Grid.Column width={8} stretched>
                    <h1>{title}</h1>
                    {children}
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default AccountPageWrap;
