import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
import AccountSettingsForm from 'src/components/account/AccountSettingsForm';
import Actions from 'src/state/root-actions';
import withLocation from 'src/components/withLocation';

const AccountSettings = ({ location: { pathname, state }, dispatch }) => {
    const [userAttrs, setUserAttrs] = useState({});
    const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState(
        false
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const onSuccess = (attrs) => {
            if (attrs?.user) {
                setUserAttrs(attrs.user);
            }
        };

        dispatch(Actions.account.getAllAttrs.trigger({ onSuccess }));

        if (state?.isChangePasswordSuccess) {
            setIsChangePasswordSuccess(true);
            state.isChangePasswordSuccess = false;

            // remove isChangePasswordSuccess state
            if (window?.history) {
                window.history.replaceState({}, '', window.location.href);
            }
        }
    }, []);

    return (
        <AccountPageWrap path={pathname} title="My Account">
            <AccountSettingsForm
                userAttrs={userAttrs}
                isChangePasswordSuccess={isChangePasswordSuccess}
                changePasswordRedirectPath={pathname}
            />
        </AccountPageWrap>
    );
};

export default connect()(withLocation(AccountSettings));
