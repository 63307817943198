import React from 'react';
import { Grid, Icon, Label, Table } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
import Selectors from 'src/state/root-selectors.js';
import accountStyles from 'src/styles/account.module.scss';

const headerCell = ({ key, title, align = 'left' }) => (
    <Table.HeaderCell key={key} textAlign={align}>
        {title || key}
    </Table.HeaderCell>
);

const BodyRow = ({ sku, amount, quantity, name }) => {
    return (
        <Table.Row key={sku}>
            <Table.Cell width={4}>{name}</Table.Cell>
            <Table.Cell width={3} className="heavy">
                {sku}
            </Table.Cell>
            <Table.Cell width={3}>${amount}</Table.Cell>
            <Table.Cell width={1} textAlign="right">
                {quantity}
            </Table.Cell>
            <Table.Cell width={5}>${amount * quantity}</Table.Cell>
        </Table.Row>
    );
};

const TaxesRow = ({ key, amount }, index) => {
    return (
        <Table.Row key={index}>
            <Table.Cell colSpan={4} textAlign="right">
                {key}
            </Table.Cell>
            <Table.Cell>${amount}</Table.Cell>
        </Table.Row>
    );
};

const OrderDetailsPage = ({ path, details, orderId }) => (
    <AccountPageWrap path={path} title={`Order ${orderId}`}>
        <div className={accountStyles.orderTableContainer}>
            <Link to="/account/orders">
                <Icon name="arrow left" /> Back to my orders
            </Link>
            <br />
            <Label className={accountStyles.orderDetailsStatus} width={16}>
                Status: Fulfilled {format(details.fullfilledAt, 'M/d/yy')} by
                FedEx <a href="/">#{details.trackingId}</a>.
            </Label>

            <Table
                className={accountStyles.accountOrderDetailsTable}
                compact
                fixed
            >
                <Table.Header>
                    <Table.Row>{details.headers.map(headerCell)}</Table.Row>
                </Table.Header>
                <Table.Body>
                    {details.data.map(BodyRow)}
                    {details.surcharges.map(TaxesRow)}
                    <Table.Row>
                        <Table.Cell colSpan={4} textAlign="right">
                            Total
                        </Table.Cell>
                        <Table.Cell>${details.total}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Grid columns={2} padded="vertically">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h4>Billing Address</h4>
                        {details.billingAddress.name}
                        <br />
                        {details.billingAddress.street}
                        <br />
                        {details.billingAddress.city},{' '}
                        {details.billingAddress.state}{' '}
                        {details.billingAddress.zipCode}
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <h4>Shipping Address</h4>
                        {details.shippingAddress.name}
                        <br />
                        {details.shippingAddress.street}
                        <br />
                        {details.shippingAddress.city},{' '}
                        {details.shippingAddress.state}{' '}
                        {details.shippingAddress.zipCode}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </AccountPageWrap>
);

const mapStateToProps = (state) => ({
    details: Selectors.account.orderDetails(state),
});

export default connect(mapStateToProps)(OrderDetailsPage);
