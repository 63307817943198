import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { Menu } from 'semantic-ui-react';

import accountStyles from 'src/styles/account.module.scss';

const AccountMenu = ({ active }) => {
    return (
        <Menu className={accountStyles.accountMenu} vertical fluid>
            <Menu.Item
                className={accountStyles.accountMenuItem}
                as={Link}
                to="/account"
                active={active === 'account'}
            >
                Account
            </Menu.Item>
            <Menu.Item
                className={accountStyles.accountMenuItem}
                as={Link}
                to="/account/subscription"
                active={active === 'subscription'}
            >
                Subscription
            </Menu.Item>
            <Menu.Item
                className={accountStyles.accountMenuItem}
                as={Link}
                to="/account/orders"
                active={active === 'orders'}
            >
                Orders
            </Menu.Item>
            <Menu.Item
                className={accountStyles.accountMenuItem}
                as={Link}
                to="/account/communications"
                active={active === 'communications'}
            >
                Communications
            </Menu.Item>
            <Menu.Item
                className={accountStyles.accountMenuItem}
                as={Link}
                to="/logout"
            >
                Logout
            </Menu.Item>
        </Menu>
    );
};

AccountMenu.propTypes = {
    active: PropTypes.oneOf([
        'account',
        'subscription',
        'orders',
        'communications',
    ]),
};

export default AccountMenu;
