import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Checkbox, Grid } from 'semantic-ui-react';

import Actions from 'src/state/root-actions';
import AccountPageWrap from 'src/components/account/AccountPageWrap';

const AccountCommunications = ({ dispatch, path }) => {
    const [communicationPref, setCommunicationPref] = useState();
    const [emailAttrsVersion, setEmailAttrsVersion] = useState();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const onSuccess = ({ version, object: { opt_out } }) => {
            setCommunicationPref(opt_out);
            setEmailAttrsVersion(version);
        };

        dispatch(Actions.account.getEmailAttrs.trigger({ onSuccess }));
    }, []);

    const toggleCommunicationPref = () => {
        const newVersion = emailAttrsVersion + 1;
        const newCommunicationPref = !communicationPref;

        /** TODO: what is the proper error messaging here? */
        const onFailure = (message) => console.log(message);
        const onSuccess = () => {
            setCommunicationPref(newCommunicationPref);
            setEmailAttrsVersion(newVersion);
        };

        dispatch(
            Actions.account.saveEmailAttrs.trigger({
                optOut: newCommunicationPref,
                version: newVersion,
                onFailure,
                onSuccess,
            })
        );
    };

    return (
        <AccountPageWrap path={path} title="Communication Preferences">
            <Grid container>
                <Grid.Column width={4}>
                    <Checkbox
                        label={communicationPref ? 'YES' : 'NO'}
                        checked={communicationPref}
                        onChange={toggleCommunicationPref}
                        toggle
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <p>
                        I want to receive emails from Pvolve about news and
                        discounts
                    </p>
                </Grid.Column>
            </Grid>
        </AccountPageWrap>
    );
};

export default connect()(AccountCommunications);
